<script>
import Button from './Button';
import Loader from './Loader';

export default {
  components: {
    Loader,
    Button
  },
  props: {
    appOptions: Object,
    loading: Boolean,
    id: Number,
    index: Number,
    name: String,
    ipfsLink: String,
    gatewayLink: String,
    state: String,
    minted: Boolean,
    policyId: String,
    assetId: String,
    assetname: String,
    fingerprint: String,
    initialMintTxHash: String,
    series: String
  },
  emits: ['change-step'],
  methods: {
    async handleClick(event) {
      event.preventDefault();
      const { appOptions, $emit } = this;
      $emit('change-step', appOptions.steps.preview);
    }
  }
};
</script>

<template>
  <article class="nft">
    <header class="nft__header">
      <svg class="nft__icon" width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3998 14.4383C11.6677 14.4383 12.6956 13.3982 12.6956 12.1151C12.6956 10.8321 11.6677 9.79199 10.3998 9.79199C9.13186 9.79199 8.104 10.8321 8.104 12.1151C8.104 13.3982 9.13186 14.4383 10.3998 14.4383Z"
          fill="#F8AB55"
        />
        <path
          d="M10.3998 24C11.338 24 12.0985 23.2305 12.0985 22.2811C12.0985 21.3318 11.338 20.5623 10.3998 20.5623C9.46168 20.5623 8.70117 21.3318 8.70117 22.2811C8.70117 23.2305 9.46168 24 10.3998 24Z"
          fill="#FB2D2B"
        />
        <path
          d="M0.850196 8.52089C1.1076 8.67185 1.39981 8.75139 1.69731 8.75151C1.84644 8.75137 1.99492 8.73154 2.13898 8.69251C2.46403 8.60489 2.75606 8.42158 2.97803 8.16582C3.20001 7.91006 3.34194 7.59336 3.38585 7.25588C3.42975 6.91839 3.37364 6.57532 3.22463 6.27014C3.07562 5.96497 2.84042 5.71143 2.54885 5.54167C2.35553 5.42881 2.14214 5.35558 1.92086 5.32617C1.69957 5.29676 1.47473 5.31174 1.25917 5.37025C0.823824 5.48843 0.452701 5.77677 0.227446 6.17184C0.00219122 6.56691 -0.0587448 7.03635 0.0580435 7.47688C0.174832 7.91741 0.459778 8.29296 0.850196 8.52089Z"
          fill="#F9792F"
        />
        <path
          d="M18.6597 8.69276C18.8038 8.73165 18.9522 8.75148 19.1014 8.75176C19.3988 8.75152 19.691 8.67198 19.9485 8.52114C20.2888 8.3223 20.5504 8.01021 20.6888 7.63805C20.8272 7.2659 20.8339 6.85669 20.7076 6.48015C20.5813 6.10361 20.3299 5.78302 19.9962 5.57301C19.6625 5.363 19.2672 5.27655 18.8776 5.3284C18.488 5.38024 18.1282 5.56717 17.8595 5.85734C17.5908 6.14751 17.4299 6.52297 17.4041 6.91976C17.3783 7.31654 17.4892 7.7101 17.718 8.03339C17.9468 8.35669 18.2793 8.58971 18.6588 8.69276H18.6597Z"
          fill="#F8AB55"
        />
        <path
          d="M7.63816 4.8447L10.4003 3.22861L13.1625 4.8447V8.8876H15.9247V3.23218L10.4003 0L4.87598 3.23218V8.8876H7.63816V4.8447Z"
          fill="#F8AB55"
        />
        <path
          d="M15.9238 8.8877L14.5431 11.3083L18.0023 13.3302L18.0049 16.5588L15.2401 18.1713L11.7809 16.1494L10.3994 18.5699L15.2392 21.3981L20.768 18.1731L20.7636 11.715L15.9238 8.8877Z"
          fill="#FB2D2B"
        />
        <path
          d="M9.01828 16.1494L5.55915 18.1713L2.79432 16.5588L2.79697 13.3302L6.2561 11.3083L4.87545 8.8877L0.0356667 11.715L0.03125 18.1731L5.56003 21.3981L10.3998 18.5699L9.01828 16.1494Z"
          fill="#F9792F"
        />
      </svg>
      <h1 class="nft__heading">{{ name }}</h1>
      <h2 class="nft__subheading">Price: <span>200 ADA</span></h2>
    </header>
    <div class="nft__content"></div>
    <div class="nft__footer">
      <div class="nft__loader" v-if="loading"><Loader>Checking status...</Loader></div>
      <Button class="nft__button" @click="this.handleClick" variant="ghost" :disabled="state !== 'free' || loading">
        Preview NFT
      </Button>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.nft {
  &__header {
    margin-bottom: 0.5rem;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  &__subheading {
    color: $color-primary;
    font-size: 1rem;
    font-weight: 400;
  }

  &__content {
    padding: 0.25rem 0;
  }

  &__icon {
    margin-bottom: 0.25rem;
  }

  &__price {
    color: $color-primary;
  }

  &__loader {
    margin-bottom: 0.5rem;
  }

  &__footer {
    margin-top: 0.25rem;
  }

  &__button {
    text-align: center;
    width: 100%;
  }
}
</style>
