<script>
import countdownTimer from 'countdown-js';
import Button from './Button.vue';
import Card from './Card.vue';
import Content from './Content.vue';
import QrCode from './QrCode.vue';
import Loader from './Loader.vue';

export default {
  name: 'CheckoutFlow',
  components: {
    Button,
    Card,
    Content,
    Loader,
    QrCode
  },
  props: {
    appOptions: Object,
    currentItem: Object,
    nextStep: String,
    fetchData: Function,
    loading: Boolean
  },
  data() {
    return {
      currentStep: '',
      expirationTime: null,
      pollStatus: () => {}
    };
  },
  emits: ['change-item', 'change-step', 'change-modal', 'change-loading'],
  methods: {
    async preview() {
      const { appOptions, currentItem, fetchData, $emit } = this;

      $emit('change-loading', true);

      const { id } = currentItem;
      const response = await fetchData(appOptions.apiUrl.getSingle(id));

      $emit('change-item', { id, data: response });

      if (!response) {
        $emit('change-step', appOptions.steps.error);
      } else if (response.errorCode) {
        $emit('change-step', appOptions.steps.expired);
      } else {
        this.currentStep = this.appOptions.steps.preview;
      }

      $emit('change-loading', false);
      $emit('change-modal', true);
    },
    async handlePreview(event) {
      event.preventDefault();
      const checkbox = event.target.elements.consent;
      const hasConsented = checkbox.checked;
      const { consentError } = this.$refs;

      if (hasConsented) {
        consentError.style.display = 'none';
        this.$emit('change-step', 'PURCHASE');
      } else {
        consentError.style.display = 'block';
      }
    },
    async purchase() {
      const { appOptions, currentItem, $emit, checkStatus, fetchData, startCountDown } = this;
      const { id } = currentItem;

      $emit('change-loading', true);

      const response = await fetchData(appOptions.apiUrl.getPaymentAddress(id));

      $emit('change-item', { id, data: response });
      $emit('change-loading', false);

      if (response.errorCode) {
        $emit('change-step', appOptions.steps.expired);
      } else {
        const { paymentAddress, expires } = response;

        startCountDown(expires);

        this.currentStep = this.appOptions.steps.purchase;

        await checkStatus(paymentAddress);

        // Poll data
        this.pollStatus = setInterval(() => checkStatus(paymentAddress), 20000);
      }
    },
    async checkStatus(paymentAddress) {
      const { appOptions, currentItem, fetchData, $emit } = this;
      const { id } = currentItem;

      $emit('change-loading', true);

      const response = await fetchData(appOptions.apiUrl.getPaymentStatus(paymentAddress));

      $emit('change-item', { id, data: response });
      $emit('change-loading', false);

      if (response.state === 'paid') {
        clearInterval(this.pollStatus);
        $emit('change-step', appOptions.steps.success);
      } else if (response.errorCode || response.state !== 'active') {
        clearInterval(this.pollStatus);
        $emit('change-step', appOptions.steps.expired);
      }
    },
    error() {
      const { $emit, appOptions } = this;

      this.currentStep = appOptions.steps.error;
      $emit('change-modal', true);
    },
    startCountDown(expires) {
      const endDate = new Date(expires);

      countdownTimer.timer(
        endDate,
        timeLeft => {
          this.expirationTime = `
                    ${timeLeft.hours.toLocaleString(undefined, {
                      minimumIntegerDigits: 2
                    })}:${timeLeft.minutes.toLocaleString(undefined, {
            minimumIntegerDigits: 2
          })}:${timeLeft.seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
                    `;
        },
        // eslint-disable-next-line
        () => {
          this.expirationTime = 'expired';
        }
      );
    },
    onCancel(event) {
      const { $emit } = this;
      event.preventDefault();

      $emit('change-modal', false);
      $emit('change-step', false);
      this.currentStep = false;
    },
    refreshPage() {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  },
  computed: {
    poolPmUrl() {
      const metadata = JSON.parse(this.currentItem.metadata);
      const id = Object.keys(metadata)[0];
      const policyId = Object.keys(metadata[id])[0];
      const assetName = Object.keys(metadata[id][policyId])[0];

      return `https://pool.pm/${policyId}.${assetName}`;
    },
    videoSrc() {
      const metadata = JSON.parse(this.currentItem.metadata);
      const id = Object.keys(metadata)[0];
      const policyId = Object.keys(metadata[id])[0];
      const assetName = Object.keys(metadata[id][policyId])[0];
      const videoSrc = metadata[id][policyId][assetName].files[0].src;

      return `${videoSrc.replace('ipfs://', 'https://gw2.easy-ipfs.com/ipfs/')}`;
    }
  },
  watch: {
    nextStep: {
      handler(value) {
        const { appOptions, preview, purchase, error } = this;

        switch (value) {
          case appOptions.steps.preview:
            preview();
            break;
          case appOptions.steps.purchase:
            purchase();
            break;
          case appOptions.steps.success:
            this.currentStep = appOptions.steps.success;
            break;
          case appOptions.steps.expired:
            this.currentStep = appOptions.steps.expired;
            break;
          case appOptions.steps.error:
            error();
            break;
          default:
            this.currentStep = '';
            break;
        }
      }
    }
  }
};
</script>

<template>
  <!-- PREVIEW -->
  <template v-if="currentStep === 'PREVIEW'">
    <form @submit="handlePreview">
      <Content>
        <template v-slot:leftColumn>
          <img :src="currentItem.gatewayLink" />
        </template>
        <template v-slot:heading>{{ currentItem.name }}</template>
        <template v-slot:subheading>Price: 200 ADA</template>
        <template v-slot:default>
          <div>
            <p>
              Thank you for considering this Founding Community NFT as part of our limited, never to be repeated,
              founders series. Holders of Founder NFTs will gain exclusive privileges once the Empowa platform launches
              in 2022.
            </p>
            <p>
              To purchase this NFT please click the ‘Proceed’ button below. A unique payment address will be displayed
              and this NFT will be reserved for 20 minutes while you transfer the funds using your Cardano wallet.
            </p>
            <p>
              Please ensure that this is your preferred selection and that you are ready to send payment, as once you
              click “Proceed” you will not be able to select another NFT until the timer expires.
            </p>

            <p>
              <strong>NOTE:</strong> You <strong>MUST</strong> use a Shelley-era Cardano wallet, such as Daedalus,
              Yoroi, or AdaLite to pay for this NFT.
            </p>

            <p>
              <strong>WARNING:</strong> Do <strong>NOT</strong> send funds from an exchange or unsupported wallet.
              Empowa will not be able to refund ADA sent from an exchange and the NFT purchased may not be accessible.
            </p>

            <p>
              <input type="checkbox" name="consent" id="consent" style="margin-right: 5px" />
              <label for="consent"
                >I understand that if I do not use a Shelley-era Cardano wallet or if I send payment from an exchange,
                that my funds may be lost and the NFT not issued to me.</label
              >
            </p>
            <p style="color: red; display: none" ref="consentError">You must consent before you can continue.</p>
          </div>
        </template>
        <template v-slot:footer>
          <Loader style="margin: 1rem 0" v-if="loading">Generating payment address...</Loader>
          <Button variant="ghost-secondary" style="margin: 0 1rem 1rem 0" type="submit" @click="onCancel"
            >Back to NFT Canvas</Button
          >
          <Button variant="primary" style="margin-bottom: 1rem" type="submit">Proceed to purchase</Button>
        </template>
      </Content>
    </form>
  </template>

  <!-- PURCHASE -->
  <template v-if="currentStep === 'PURCHASE'">
    <Content>
      <template v-slot:leftColumn>
        <div style="margin-bottom: 1rem">
          <h3>Payment instructions</h3>
          <p>
            Transfer <strong>{{ currentItem.adaToSend }} ADA</strong> by using the payment address below.
          </p>
        </div>
        <div>
          <QrCode style="margin: 1.5rem 0" :url="currentItem.paymentAddress" label="Payment Address" />
        </div>
        <div>
          <h3>Status</h3>
          <Loader style="margin: 1rem 0" v-if="loading">Getting status...</Loader>
          <div style="font-size: 1.3rem; color: #f9792f">
            <p style="margin: 0">
              Current status: <span style="text-transform: uppercase">{{ currentItem.state }}</span>
            </p>
            <p style="font-weight: 700">Expires in: {{ expirationTime }}</p>
          </div>
        </div>
      </template>
      <template v-slot:heading> NFT Purchase </template>
      <template v-slot:subheading> You're about to buy </template>
      <template v-slot:default>
        <div style="margin-bottom: 1.5rem">
          <Card style="margin-bottom: 1rem">
            <template v-slot:image>
              <img :src="currentItem.gatewayLink" />
            </template>
            <template v-slot:heading>{{ currentItem.name }}</template>
            <template v-slot:subHeading> Price: 200 ADA </template>
          </Card>
        </div>
        <p>Please send your payment to the displayed address prior to the timer expiring.</p>
        <p>
          Transactions completed after the timer has expired will be invalid and will not receive the associated NFT.
        </p>
        <p>Once the NFT purchase has been completed, it will be revealed showing its type and serial number.</p>
        <p>
          The NFT will be sent to the wallet address used to make payment, after which you can also view its contents on
          sites like <a href="https://pool.pm" target="_blank">https://pool.pm</a>.
        </p>
      </template>
      <template v-slot:footer>
        <Button v-if="expirationTime === 'expired'" variant="ghost-secondary" @click="onCancel"
          >Back to NFT Canvas</Button
        >
      </template>
    </Content>
  </template>

  <!-- EXPIRED -->
  <template v-if="currentStep === 'EXPIRED'">
    <Content>
      <template v-slot:heading> Purchase failed </template>
      <template v-slot:subheading> Expired </template>
      <template v-slot:default>
        <div style="margin-bottom: 1.5rem">
          <Card style="margin-bottom: 1rem">
            <template v-slot:image>
              <img :src="currentItem.gatewayLink" />
            </template>
            <template v-slot:heading>{{ currentItem.name }}</template>
            <template v-slot:subHeading> Price: 200 ADA </template>
          </Card>
          <p>This payment address has expired.</p>
          <p>All payments to this address will not be processed.</p>
        </div>
      </template>
      <template v-slot:footer>
        <Button variant="ghost-secondary" style="margin-bottom: 1rem" @click="onCancel">Back to NFT Canvas</Button>
      </template>
    </Content>
  </template>

  <!-- SUCCESS -->
  <template v-if="currentStep === 'SUCCESS'">
    <Content>
      <template v-slot:heading> Thank you </template>
      <template v-slot:subheading> You have purchased the Empowa Founding Community NFT. </template>
      <template v-slot:default>
        <video autoplay="" name="media" style="max-width: 512px; margin-bottom: 2rem">
          <source :src="videoSrc" type="video/mp4" />
        </video>
        <p>
          Your NFT will arrive in your wallet and be visible on <a href="https://pool.pm" target="_blank">pool.pm</a> in
          a few minutes.
        </p>
        <p>
          <small
            >If your NFT does not appear in your wallet after 15 minutes, please email us at
            <a href="mailto:nft.support@empowa.io">nft.support@empowa.io</a> with your payment transaction hash</small
          >
        </p>
      </template>
      <template v-slot:footer>
        <Button variant="ghost-secondary" style="margin: 0 1rem 1rem 0" @click="onCancel">Back to NFT Canvas</Button>
        <Button variant="primary" :href="poolPmUrl" target="_blank">View on pool.pm</Button>
      </template>
    </Content>
  </template>

  <!-- ERROR -->
  <template v-if="currentStep === 'ERROR'">
    <Content>
      <template v-slot:heading> Oops </template>
      <template v-slot:subheading> Something has gone wrong... </template>
      <template v-slot:default>
        <p>It looks like we can't get reach the server. We're sorry for the inconvenience. Please try again later.</p>
      </template>
      <template v-slot:footer>
        <Button variant="ghost-secondary" style="margin-bottom: 1rem" @click="refreshPage">Refresh NFT canvas</Button>
      </template>
    </Content>
  </template>
</template>
