<script>
export default {
  computed: {
    leftColumnSlot() {
      return !!this.$slots.leftColumn;
    },
    headingSlot() {
      return !!this.$slots.leftColumn;
    },
    subheadingSlot() {
      return !!this.$slots.leftColumn;
    }
  }
};
</script>

<template>
  <section class="content" :class="leftColumnSlot && 'content--columns'">
    <div v-if="leftColumnSlot" class="content__aside" :class="leftColumnSlot && 'content__column'">
      <slot name="leftColumn"></slot>
    </div>
    <div class="content__container" :class="leftColumnSlot && 'content__column'">
      <header class="content__header">
        <h1 class="content__heading"><slot name="heading"></slot></h1>
        <h2 class="content__subheading"><slot name="subheading"></slot></h2>
        <div class="content__divider" />
      </header>
      <div class="content__main">
        <slot></slot>
      </div>
      <div class="content__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import '../assets/scss/variables';

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100%;

  &__heading {
    font-size: 2.375rem;
    font-weight: 700;
  }

  &__subheading {
    color: $color-primary;
    font-size: 1.75rem;
  }

  &__aside {
    background-color: $color-background;
    padding: 2rem;
  }

  &__container {
    margin: 0 auto;
    max-width: 1024px;
    padding: 2rem;
  }

  &__main {
    margin: 2rem 0;
  }

  &__divider {
    width: 20%;
    height: 4px;
    background-color: $color-primary;
    margin: 1rem 0;
  }

  &--columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .content__container {
      margin: 0;
    }

    .content__column {
      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &__heading {
      font-size: 3rem;
      font-weight: 700;
    }

    &__subheading {
      font-size: 2.375rem;
    }

    &--columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .content__column {
        &:first-child {
          order: 1;
          width: 33.33%;
        }
        &:last-child {
          order: 2;
          flex: 1;
        }
      }
    }
  }
}
</style>
