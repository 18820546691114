<script>
export default {};
</script>

<template>
  <section class="card">
    <div class="card__column">
      <div class="card__media">
        <slot name="image"></slot>
      </div>
    </div>
    <div class="card__column">
      <div class="card__container">
        <header class="card__header">
          <h2 class="card__heading"><slot name="heading"></slot></h2>
          <h3 class="card__subheading"><slot name="subHeading"></slot></h3>
        </header>
        <div class="card__main">
          <slot></slot>
        </div>
        <div class="card__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import '../assets/scss/variables';

.card {
  align-items: center;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &__heading {
    font-size: 1.125rem;
  }

  &__subheading {
    color: $color-primary;
    font-size: 1rem;
  }

  &__column {
    &:first-child {
      max-width: 150px;
    }

    &:last-child {
      flex: 1;
      padding: 1rem;
    }
  }

  &__media {
    img {
      object-fit: cover;
    }
  }

  @media screen and (min-width: 768px) {
    &__heading {
      font-size: 1.25rem;
    }
  }
}
</style>
