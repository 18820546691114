<script>
export default {
  props: {
    open: Boolean
  },
  watch: {
    open: {
      handler(value) {
        if (value) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
        }
      }
    }
  }
};
</script>

<template>
  <section class="modal" :class="open && 'is-open'">
    <div class="modal__container">
      <slot></slot>
    </div>
  </section>
</template>

<style lang="scss">
.modal {
  display: flex;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.25s ease-in-out;
  width: 100%;
  z-index: -1;

  &.is-open {
    opacity: 1;
    pointer-events: all;
    z-index: 2;

    .modal__container {
      transform: scale(1);
    }
  }

  &__container {
    background-color: #fff;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    margin: auto;
    transform: scale(0.99);
    transition: transform 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
    width: 100%;
  }
}
</style>
