<script>
import tippy from 'tippy.js';
import { nextTick } from 'vue';
import Nft from './Nft';

export default {
  components: {
    Nft
  },
  props: {
    appOptions: Object,
    loading: Boolean,
    items: Array,
    currentItem: Object,
    handleNextStep: Function,
    fetchData: Function
  },
  emits: ['change-item', 'change-step', 'change-modal', 'change-loading'],
  mounted() {
    this.initTooltips();
  },
  methods: {
    async handleClick(event) {
      event.preventDefault();
    },
    initTooltips() {
      const tooltipTriggers = [...this.$refs.triggers.children];
      const tooltipContent = this.$refs.content;
      const { $emit, fetchData, appOptions } = this;

      nextTick(() => {
        tippy(tooltipTriggers, {
          theme: 'light',
          trigger: 'click',
          hideOnClick: true,
          maxWidth: 'none',
          placement: 'top',
          allowHTML: true,
          interactive: true,
          animation: 'shift-away',
          zIndex: 1,
          duration: [700, 100],
          async onTrigger(instance) {
            instance.setContent('Getting information...');
            const { id } = instance.reference.dataset;
            const response = await fetchData(appOptions.apiUrl.getSingle(id));
            $emit('change-item', { id, data: { ...response } });
            instance.setContent(tooltipContent);
          }
        });
      });
    }
  }
};
</script>

<template>
  <ul class="nft-list" ref="triggers">
    <li
      class="nft-list__item"
      :data-name="item.name"
      :data-id="item.id"
      v-for="item in items"
      :key="item.id"
      :class="item.state === 'free' && 'is-available'"
    ></li>
  </ul>
  <div style="display: none">
    <div ref="content">
      <Nft
        @change-step="this.$emit('change-step', $event)"
        :app-options="appOptions"
        :key="currentItem.name"
        :id="currentItem.id"
        :name="currentItem.name"
        :ipfs-link="currentItem.ipfsLink"
        :gateway-link="currentItem.gatewayLink"
        :state="currentItem.state"
        :minted="currentItem.minted"
        :policy-id="currentItem.policyId"
        :asset-id="currentItem.assetId"
        :assetname="currentItem.assetname"
        :fingerprint="currentItem.fingerprint"
        :initial-mint-tx-hash="currentItem.initialMintTxHash"
        :series="currentItem.series"
        :handle-next-step="handleNextStep"
        :loading="loading"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.nft-list {
  background-image: url('../assets/images/nft-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  height: 0;
  list-style: none;
  padding-bottom: calc(9 / 16 * 100%);
  position: relative;
  width: 100%;

  &__item {
    color: #410000;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 0 1px rgba(249, 121, 47, 0.2);
    -moz-box-shadow: inset 0 0 0 1px rgba(249, 121, 47, 0.2);
    box-shadow: inset 0 0 0 1px rgba(249, 121, 47, 0.2);
    pointer-events: none;
    width: 100%;
    height: 0;
    opacity: 1;
    padding-bottom: calc(9 / 16 * 100%);
    transition: background-color 0.25s ease-in-out;

    &.is-available {
      background-color: #410000;
      pointer-events: all;
    }

    &:hover,
    &[aria-expanded='true'] {
      background-color: transparentize(#410000, 0.35);
    }
  }

  @media (orientation: portrait) and (max-width: 767px) {
    height: 1080px;
    padding-bottom: 0;
    width: 1920px;

    &__item {
      height: auto;
      padding-bottom: 0;
    }
  }
}
</style>
