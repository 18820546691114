<script>
import Loader from './components/Loader.vue';
import Modal from './components/Modal.vue';
import NftList from './components/NftList.vue';
import CheckoutFlow from './components/CheckoutFlow.vue';
import { appConfig } from './config';

export default {
  components: {
    Loader,
    NftList,
    Modal,
    CheckoutFlow
  },
  data() {
    return {
      options: {
        ...appConfig
      },
      loading: false,
      items: [],
      currentItem: {
        expires: null,
        pollStatus: null
      },
      nextStep: '',
      modal: false
    };
  },
  async created() {
    const { options, updateLoading, modifyInitialItems } = this;

    updateLoading(true);
    const items = await this.fetchData(options.apiUrl.getAll);
    updateLoading(false);

    if (items) {
      const modifiedItems = modifyInitialItems(items);

      this.items = modifiedItems;
    }
  },
  methods: {
    fetchData(url) {
      return fetch(url)
        .then(res => {
          if (res.status >= 400 && res.status < 600) {
            this.nextStep = this.options.steps.error;
          }
          return res.json();
        })
        .catch(() => {
          this.nextStep = this.options.steps.error;
        });
    },
    modifyInitialItems(items) {
      const { soldItems } = this.options;

      return items
        .map(item => {
          soldItems.map(soldItem => {
            if (+soldItem === +item.id) {
              item.state = 'paid';
            }

            return soldItem;
          });

          return item;
        })
        .sort((a, b) => a.id - b.id);
    },
    updateItems({ id, data = false }) {
      const { items } = this;
      const currentItem = items.find(item => +item.id === +id);
      const currentItemIndex = items.findIndex(item => +item.id === +currentItem.id);

      const newItem = {
        ...currentItem,
        ...data
      };

      items[currentItemIndex] = {
        ...currentItem,
        ...data
      };

      this.items = [...items];
      this.currentItem = { ...newItem };
    },
    updateNextStep(step) {
      if (step) {
        this.nextStep = step;
      } else {
        this.nextStep = '';
      }
    },
    updateModal(value) {
      this.modal = value;
    },
    updateLoading(value) {
      this.loading = value;
    }
  }
};
</script>

<template>
  <section class="nft-grid">
    <Loader v-if="items.length === 0" class="nft-grid__loader">Getting NFTs...</Loader>
    <NftList
      @change-step="updateNextStep"
      @change-loading="updateLoading"
      @change-modal="updateModal"
      @change-item="updateItems"
      :fetch-data="fetchData"
      :app-options="options"
      :items="items"
      :current-item="currentItem"
      :loading="loading"
      v-if="items.length !== 0"
    ></NftList>
    <Modal :open="modal">
      <CheckoutFlow
        @change-step="updateNextStep"
        @change-loading="updateLoading"
        @change-modal="updateModal"
        @change-item="updateItems"
        :fetch-data="fetchData"
        :next-step="nextStep"
        :app-options="options"
        :current-item="currentItem"
      ></CheckoutFlow>
    </Modal>
  </section>
</template>

<style lang="scss">
@import 'assets/scss/variables';

.nft-grid {
  &__loader {
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
</style>
