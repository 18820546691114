<script>
import { nextTick } from 'vue';
import QRCodeStyling from 'qr-code-styling';
import tippy from 'tippy.js';
import Logo from '../assets/images/logo.svg';

export default {
  props: {
    url: String,
    label: String
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.createQrCode();
      this.createTooltip();
    },
    createQrCode() {
      const { image } = this.$refs;
      const qrCode = new QRCodeStyling({
        width: 320,
        height: 320,
        data: this.url,
        image: Logo,
        dotsOptions: {
          color: '#410000',
          type: 'rounded'
        },
        imageOptions: {
          margin: 5
        }
      });

      // eslint-disable-next-line
      qrCode.append(image);
    },
    createTooltip() {
      const { url, $refs } = this;
      const { trigger } = $refs;

      nextTick(() => {
        tippy(trigger, {
          theme: 'light',
          trigger: 'click',
          hideOnClick: true,
          maxWidth: 'none',
          placement: 'top',
          allowHTML: true,
          interactive: true,
          animation: 'shift-away',
          zIndex: 1,
          duration: [700, 100],
          async onTrigger(instance) {
            await navigator.clipboard.writeText(url);
            instance.setContent('Copied to clipboard!');
          }
        });
      });
    }
  }
};
</script>

<template>
  <div class="qr-code">
    <div ref="image" class="qr-code__image" />
    <div class="qr-code__box">
      <span class="qr-code__label" v-if="label">{{ label }}:</span>
      <div class="qr-code__code">
        <span class="qr-code__link">{{ url }}</span>
        <div class="qr-code__copy" ref="trigger">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 10V9C6 7.346 7.346 6 9 6H10V2.667C10 2.299 9.701 2 9.333 2H2.667C2.299 2 2 2.299 2 2.667V9.333C2 9.701 2.299 10 2.667 10H6ZM6 12H2.667C1.196 12 0 10.804 0 9.333V2.667C0 1.196 1.196 0 2.667 0H9.333C10.804 0 12 1.196 12 2.667V6H15C16.654 6 18 7.346 18 9V15C18 16.654 16.654 18 15 18H9C7.346 18 6 16.654 6 15V12ZM8 9C8 8.449 8.449 8 9 8H15C15.552 8 16 8.449 16 9V15C16 15.551 15.552 16 15 16H9C8.449 16 8 15.551 8 15V9Z"
              fill="#2E3A59"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../assets/scss/variables';

.qr-code {
  background: $color-background--secondary;
  border-radius: 4px;
  padding: 0.75rem;
  max-width: 300px;

  &__image {
    width: 100%;
  }

  &__box {
    font-size: 0.75rem;
  }

  &__code {
    display: flex;
    background-color: $color-grey;
  }

  &__link {
    display: block;
    word-break: break-all;
    padding: 0.5rem;
    flex: 1;
  }

  &__copy {
    background-color: $color-grey--light;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
  }

  canvas {
    width: 100%;
  }
}
</style>
