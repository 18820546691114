export const appConfig = {
  steps: {
    preview: 'PREVIEW',
    purchase: 'PURCHASE',
    success: 'SUCCESS',
    expired: 'EXPIRED',
    error: 'ERROR'
  },
  apiUrl: {
    testAll: '',
    getAll: 'https://cw5486zqo3.execute-api.us-east-2.amazonaws.com/all',
    getSingle: id => `https://cw5486zqo3.execute-api.us-east-2.amazonaws.com/nft/${id}`,
    getPaymentAddress: id => `https://cw5486zqo3.execute-api.us-east-2.amazonaws.com/address/${id}`,
    getPaymentStatus: address => `https://cw5486zqo3.execute-api.us-east-2.amazonaws.com/payment/${address}`
  },
  soldItems: [
    233249, 233253, 233281, 233313, 233325, 233359, 233363, 233377, 233391, 233395, 233411, 233419, 233425, 233495,
    233513, 233658, 233668, 233706, 233728, 233774, 233798, 233800, 233804, 233812, 233824, 233842, 233874, 233880,
    233890, 234042, 234054, 234126, 234218, 234242, 234296, 234306, 234354, 234356, 234360, 234386, 234432, 234442,
    234456, 234534, 234554, 234600, 234622, 234672, 234720, 234736, 234774, 234856, 234864, 234876, 234968, 234972,
    235014, 235092, 235094, 235176, 235224, 235234, 235256, 235258
  ]
};
